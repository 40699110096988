/* Step 3 Styles */
.scheduler-section {
  padding: 16px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 100%;
  box-sizing: border-box;
}

.mode-options {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  margin-bottom: 24px;
}

.mode-content {
  min-height: 130px;
  padding: 20px;
  border: 1px solid #E5E7EB;
  border-radius: 12px;
  background: #FFFFFF;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.mode-content:hover {
  border-color: #6366F1;
  box-shadow: 0 4px 12px rgba(99, 102, 241, 0.1);
  transform: translateY(-1px);
}

.mode-option.selected .mode-content {
  border-color: #6366F1;
  background: linear-gradient(to bottom right, #F5F7FF, #FFFFFF);
  box-shadow: 0 4px 12px rgba(99, 102, 241, 0.15);
}

.mode-title {
  font-size: 16px;
  font-weight: 600;
  color: #111827;
  margin-bottom: 8px;
  letter-spacing: -0.01em;
}

.mode-description {
  font-size: 14px;
  color: #6B7280;
  line-height: 1.4;
  letter-spacing: -0.01em;
}

.input-group {
  position: relative;
  margin: 0 0 2px;
}

.input-group label {
  display: block;
  font-weight: 600;
  margin-bottom: 6px;
  color: #374151;
  font-size: 14px;
  letter-spacing: -0.01em;
}

.scheduler-input {
  width: 100%;
  padding: 0 16px;
  border: 1px solid #E5E7EB;
  border-radius: 10px;
  font-size: 14px;
  height: 44px;
  background: #FFFFFF;
  transition: all 0.2s ease;
  box-sizing: border-box;
  letter-spacing: -0.01em;
  color: #111827;
}

.scheduler-input::placeholder {
  color: #9CA3AF;
  opacity: 0.8;
}

.scheduler-input:hover {
  border-color: #D1D5DB;
}

.scheduler-input:focus {
  border-color: #6366F1;
  outline: none;
  box-shadow: 0 0 0 3px rgba(99, 102, 241, 0.1);
}

.info-box {
  padding: 10px 12px;
  background: #F9FAFB;
  border-radius: 8px;
  border: 1px solid #F3F4F6;
  margin-bottom: 2px;
}

.info-box h4 {
  font-size: 13px;
  font-weight: 500;
  color: #6B7280;
  margin: 0 0 2px;
  letter-spacing: -0.01em;
}

.info-box p {
  font-size: 13px;
  color: #9CA3AF;
  line-height: 1.4;
  margin: 0;
  letter-spacing: -0.01em;
}

.mission-summary {
  padding: 12px;
  background: #F8FAFC;
  border-radius: 10px;
  border: 1px solid #E2E8F0;
  margin-top: 0px;
  margin-bottom: 4px;
}

.mission-header {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 4px;
}

.mission-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  font-size: 16px;
  background: linear-gradient(135deg, #F5F7FF 0%, #FFFFFF 100%);
  border-radius: 6px;
  border: 1px solid rgba(99, 102, 241, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.mission-header h4 {
  font-size: 14px;
  font-weight: 600;
  color: #374151;
  margin: 0;
  letter-spacing: -0.01em;
}

.mission-text {
  font-size: 14px;
  line-height: 1.4;
  color: #4B5563;
  margin: 0;
  letter-spacing: -0.01em;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  background: linear-gradient(to bottom right, #374151, #4B5563);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.error-message {
  position: absolute;
  left: 0;
  bottom: -20px;
  color: #DC2626;
  font-size: 13px;
  letter-spacing: -0.01em;
}
