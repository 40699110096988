.mention-dropdown {
  position: absolute;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  min-width: 200px;
  max-width: 280px;
  max-height: 300px;
  overflow-y: auto;
  z-index: 1000;
  padding: 8px 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.mention-item {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.mention-item:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.mention-item-avatar {
  margin-right: 12px;
  display: flex;
  align-items: center;
}

.mention-item-name {
  font-size: 14px;
  color: #333;
  font-weight: 500;
}

.mention-no-results {
  padding: 12px 16px;
  color: #666;
  font-size: 14px;
  text-align: center;
}