.hover-card {
  z-index: 1000;
  min-width: 360px;
  max-width: 420px;
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0 12px 36px rgba(0, 0, 0, 0.08);
  pointer-events: none;
  position: fixed;
  transform-origin: top center;
  border: 1px solid rgba(0, 0, 0, 0.06);
  backdrop-filter: blur(8px);
}

.hover-card-content {
  padding: 24px;
}

.hover-card-header {
  display: flex;
  gap: 16px;
  margin-bottom: 20px;
  align-items: flex-start;
}

.agent-avatar {
  flex-shrink: 0;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  background: #f3f4f6;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.06);
}

.agent-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.agent-initials {
  font-size: 40px;
  font-weight: 600;
  color: #4b5563;
}

.agent-info {
  flex: 1;
  min-width: 0;
}

.agent-title {
  margin: 0 0 8px;
  padding: 2px 0;
  font-size: 20px;
  font-weight: 600;
  color: #111827;
  letter-spacing: 0.01em;
  line-height: 1.4;
  word-spacing: 0.05em;
}

.agent-role {
  margin: 0;
  font-size: 15px;
  color: #6b7280;
  line-height: 1.4;
  font-weight: 500;
}

.agent-description {
  margin: 0 0 16px;
  font-size: 14px;
  color: #4b5563;
  line-height: 1.6;
}

.agent-status {
  display: inline-flex;
  align-items: center;
  padding: 6px 12px;
  border-radius: 9999px;
  font-size: 13px;
  font-weight: 500;
  gap: 6px;
}

.agent-status::before {
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.agent-status.active {
  background-color: rgba(16, 185, 129, 0.1);
  color: #059669;
}

.agent-status.active::before {
  background-color: #059669;
  box-shadow: 0 0 0 2px rgba(16, 185, 129, 0.2);
}

.agent-status.inactive {
  background-color: rgba(239, 68, 68, 0.1);
  color: #dc2626;
}

.agent-status.inactive::before {
  background-color: #dc2626;
  box-shadow: 0 0 0 2px rgba(239, 68, 68, 0.2);
}

.agent-status.busy {
  background-color: rgba(245, 158, 11, 0.1);
  color: #d97706;
}

.agent-status.busy::before {
  background-color: #d97706;
  box-shadow: 0 0 0 2px rgba(245, 158, 11, 0.2);
}