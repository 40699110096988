.chat-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  background-color: #ECE5DD;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
}

.setup-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #000000;
  color: white;
  padding: 20px;
}

.setup-input, .setup-select {
  width: 80%;
  max-width: 400px;
  padding: 10px;
  margin: 10px 0;
  border: none;
  border-radius: 5px;
  font-size: 16px;
}

.setup-button {
  background-color: #25D366;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.setup-button:hover {
  background-color: #128C7E;
}

.chat-header {
  background-color: #ffffff;
  color: white;
  padding: 10px;
  text-align: center;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.chat-messages {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  background-color: #ECE5DD;
}

.message {
  display: flex;
  align-items: flex-start;
  margin: 10px 0;
  max-width: 80%;
}

.message.user {
  justify-content: flex-end;
  margin-left: auto;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: white;
  margin-right: 10px;
}

.message-content {
  padding: 10px 15px;
  border-radius: 18px;
  box-shadow: 0 1px 0.5px rgba(0,0,0,0.13);
  line-height: 1.4;
}

.message.agent .message-content {
  background-color: white;
  border-top-left-radius: 0;
}

.message.user .message-content {
  background-color: #DCF8C6;
  border-top-right-radius: 0;
}

.chat-input {
  display: flex;
  padding: 10px;
  background-color: #F0F0F0;
  border-top: 1px solid #D3D3D3;
}

.chat-input input {
  flex: 1;
  padding: 10px;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  background-color: white;
}

.chat-input button {
  background-color: #075E54;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-left: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-input button:hover {
  background-color: #128C7E;
}

.main-interface {
    display: flex;
    height: 100vh;
    background: #ffffff;
}

.agent-chat {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 20px;
}

.messages-container {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.email-review-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 24px;
    border-radius: 12px;
    box-shadow: 0 4px 32px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 600px;
    max-height: 80vh;
    overflow-y: auto;
}

.email-preview {
    margin-bottom: 24px;
}

.email-content {
    background: #f8f9fa;
    padding: 16px;
    border-radius: 8px;
    margin-top: 12px;
}

.email-content .subject {
    font-weight: 600;
    margin-bottom: 8px;
}

.supervised-controls {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.supervised-controls textarea {
    width: 100%;
    min-height: 100px;
    padding: 12px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    resize: vertical;
}

.actions {
    display: flex;
    gap: 12px;
    justify-content: flex-end;
}

.actions button {
    padding: 8px 16px;
    border-radius: 6px;
    font-weight: 500;
    cursor: pointer;
}

.actions button:first-child {
    background: #f8f9fa;
    border: 1px solid #e0e0e0;
}

.actions button:last-child {
    background: #0066cc;
    color: white;
    border: none;
}

.autonomous-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
}

.timer {
    font-size: 24px;
    font-weight: 600;
    color: #666;
}

.commentary-overlay {
    position: fixed;
    top: 20px;
    left: 20px;
    z-index: 1000;
    pointer-events: none;
}

.commentary-card {
    background: rgba(255, 255, 255, 0.9);
    padding: 12px 16px;
    border-radius: 8px;
    margin-bottom: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    max-width: 300px;
}

.performance-dashboard {
    background: white;
    padding: 16px;
    border-radius: 12px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 16px;
}

.metric {
    text-align: center;
}

.metric h3 {
    font-size: 14px;
    color: #666;
    margin: 0 0 4px 0;
}

.metric .value {
    font-size: 24px;
    font-weight: 600;
    color: #333;
}

.message.status {
    justify-content: center;
    margin: 8px 0;
}

.message.status .message-content {
    background: rgba(0, 0, 0, 0.05);
    color: #666;
    font-style: italic;
    padding: 8px 12px;
    border-radius: 4px;
}

.message-time {
    font-size: 12px;
    color: #999;
    margin-top: 4px;
}

/* Email Thread View */
.email-thread-view {
    position: fixed;
    right: 0;
    top: 0;
    width: 50%;
    height: 100vh;
    background: white;
    box-shadow: -2px 0 12px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
}

.thread-container {
    display: flex;
    height: 100%;
}

.prospect-panel {
    width: 300px;
    border-right: 1px solid #eee;
    padding: 20px;
}

.thread-panel {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
}

/* Prospect Profile */
.prospect-profile {
    padding: 20px;
}

.profile-header {
    margin-bottom: 24px;
}

.profile-details {
    display: grid;
    gap: 16px;
}

.detail-item {
    display: flex;
    flex-direction: column;
}

/* Notifications */
.notification-container {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.notification {
    background: white;
    padding: 12px 16px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: flex-start;
    gap: 12px;
    min-width: 300px;
}

/* Onboarding */
.onboarding-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.onboarding-step {
    background: white;
    padding: 32px;
    border-radius: 12px;
    width: 90%;
    max-width: 500px;
}

.step-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.App {
  min-height: 100vh;
  background-color: #f5f5f5;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-size: 1.2rem;
  color: #666;
}

.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  text-align: center;
}

.error-message {
  background-color: #ffebee;
  color: #c62828;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
  max-width: 500px;
  width: 100%;
}

.retry-button {
  padding: 10px 20px;
  background-color: #4285f4;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.retry-button:hover {
  background-color: #357abd;
}

.notification {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #4caf50;
  color: white;
  padding: 15px 25px;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  animation: slideIn 0.3s ease-out;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Add these styles to your App.css */
.config-error {
    padding: 2rem;
    max-width: 600px;
    margin: 2rem auto;
    background-color: #fff3f3;
    border: 1px solid #dc3545;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.config-error h1 {
    color: #dc3545;
    margin-bottom: 1rem;
    font-size: 1.5rem;
}

.config-error p {
    margin-bottom: 1rem;
    color: #333;
    line-height: 1.5;
}

.config-error pre {
    background-color: #f8f9fa;
    padding: 1rem;
    border-radius: 4px;
    overflow-x: auto;
    margin: 1rem 0;
    border: 1px solid #dee2e6;
}

/* Loading spinner styles */
.loading-spinner {
    border: 3px solid #f3f3f3;
    border-radius: 50%;
    border-top: 3px solid #3498db;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
    margin: 0 auto;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.auth-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    gap: 1rem;
    background-color: #f8f9fa;
}

.auth-loading p {
    color: #666;
    font-size: 0.9rem;
}