.dashboard {
  display: grid;
  grid-template-columns: 260px 1fr;
  gap: 24px;
  height: calc(100vh - 48px);
  padding: 24px;
  background: #FAFAFA;
  font-family: var(--font-family);
  overflow: hidden;
  position: relative;
}

/* Completely remove any background text */
.dashboard::before,
.dashboard::after,
.dashboard > *::before,
.dashboard > *::after {
  display: none !important;
  content: none !important;
}

.dashboard-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 64px;
  padding: 0 32px;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 100;
}

.metrics-section {
  position: fixed;
  left: 24px;
  top: 88px; /* Below header */
  width: 220px;
  height: calc(100vh - 160px); /* Match the old dashboard height */
  background: #FFFFFF;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);
  border: none;
  padding: 8px;
  margin-right: 24px;
  overflow: visible;
}

.main-content {
  position: fixed;
  left: 280px; /* 220px + 24px + 36px (for spacing) */
  top: 88px;
  right: 24px;
  height: calc(100vh - 150px); /* Match the old dashboard height */
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border-radius: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);
  border: none;
  overflow: hidden;
}

.connection-status {
  font-size: 14px;
  font-weight: 500;
  color: #666;
  display: flex;
  align-items: center;
  gap: 8px;
}

.connection-status::before {
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #10B981;
}

.connection-status.disconnected::before {
  background: #EF4444;
}

.header-actions {
  display: flex;
  align-items: center;
  gap: 16px;
}

.start-outreach-button {
  padding: 10px 20px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #FFFFFF;
  color: #111827;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  box-sizing: border-box;
  white-space: nowrap;
  line-height: 1;
}

.start-outreach-button:hover {
  background: #0066FF;
  color: white;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 102, 255, 0.2);
  border-color: #0066FF;
}

.start-outreach-button:active {
  transform: translateY(0);
}

.start-outreach-button.loading,
.start-outreach-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
}

.agent-chat-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}

.messages-container {
  flex: 1;
  overflow-y: auto;
  padding: 24px;
}

/* Error Message Styling */
.error-message {
  position: fixed;
  bottom: 24px;
  right: 24px;
  padding: 16px 24px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  gap: 12px;
  z-index: 1000;
  border: 1px solid rgba(239, 68, 68, 0.2);
}

.error-message button {
  padding: 6px 12px;
  border-radius: 6px;
  border: none;
  background: #EF4444;
  color: white;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
}

/* Typography */
h1, h2, h3, h4, h5, h6 {
  letter-spacing: -0.5px;
}

/* Animations */
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Scrollbars */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.2);
}

/* Update font for consistency */
* {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
}

/* Update textarea font in AgentChat */
.feedback-input {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 14px;
  color: #111827;
}

/* Update all text inputs for consistency */
input, textarea, button {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
} 