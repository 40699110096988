.google-auth-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto;
  max-width: 400px;
}

.error-message {
  color: #d32f2f;
  margin-bottom: 16px;
  text-align: center;
  font-size: 14px;
}

#googleSignInButton {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
}

#googleSignInButton > div {
  width: auto !important;
}

#googleSignInButton iframe {
  scale: 1.2;
}

.google-auth-container button {
  height: 48px !important;
  border-radius: 24px !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05) !important;
  transition: all 0.2s !important;
  background-color: #4285f4 !important;
}

.google-auth-container button:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1) !important;
  transform: translateY(-1px) !important;
  background-color: #357abd !important;
}

.google-auth-container button > div {
  font-family: 'Google Sans', Roboto, Arial, sans-serif !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  padding: 12px !important;
  color: white !important;
}

.google-auth-button {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 24px;
  background-color: #4285f4;
  color: white;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.google-auth-button:hover {
  background-color: #357abd;
}

.google-auth-button img {
  width: 24px;
  height: 24px;
}

#google-signin-button {
  min-width: 250px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
} 