.outreach-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.9);
  transform-origin: center center;
  background: white;
  border-radius: 12px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  width: 720px;
  max-width: 90vw;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  z-index: 1000;
}

.outreach-dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 2rem;
}

.outreach-dialog-content {
  flex: 1;
  overflow-y: auto;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.dialog-header {
  padding: 0.75rem 1.5rem;
  border-bottom: 1px solid #f3f4f6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  flex-shrink: 0;
}

.dialog-header h2 {
  margin: 0;
  font-size: 1.125rem;
  font-weight: 700;
  color: #374151;
  letter-spacing: -0.01em;
}

.close-button {
  width: 1.75rem;
  height: 1.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  color: #9ca3af;
  cursor: pointer;
  border-radius: 6px;
  transition: all 0.15s ease;
  padding: 0.375rem;
  margin: -0.375rem;
}

.close-button:hover {
  background: #f3f4f6;
  color: #6b7280;
}

.dialog-content {
  padding: 1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.875rem;
}

.url-section {
  padding: 0.875rem;
  background: #ffffff;
  border-radius: 16px;
  border: 1px solid #e5e7eb;
  width: 100%;
  box-sizing: border-box;
}

.url-input {
  width: 100%;
  height: 40px !important;
  min-height: 40px !important;
  max-height: 40px !important;
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid #d1d5db;
  transition: all 0.3s ease;
  outline: none;
  font-size: 14px;
  box-sizing: border-box;
  resize: none !important;
  overflow: hidden !important;
  display: block;
}

.url-input:disabled {
  animation: pulse-loading 1.5s ease-in-out infinite;
  cursor: wait;
  opacity: 0.8;
}

.url-input.valid {
  border-color: #4CAF50;
}

.url-input.valid:focus {
  border-color: #4CAF50;
  box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.1);
}

.url-input.partial {
  border-color: #FFC107;
}

.url-input.partial:focus {
  border-color: #FFC107;
  box-shadow: 0 0 0 2px rgba(255, 193, 7, 0.1);
}

.url-input.invalid {
  border-color: #f44336;
}

.url-input.invalid:focus {
  border-color: #f44336;
  box-shadow: 0 0 0 2px rgba(244, 67, 54, 0.1);
}

.url-input:hover {
  border-color: #d1d5db;
}

.url-input::placeholder {
  color: #9ca3af;
}

@keyframes pulse-loading {
  0% {
    background-color: rgba(0, 0, 0, 0.03);
    border-color: rgba(59, 130, 246, 0.3);
  }
  50% {
    background-color: rgba(0, 0, 0, 0.05);
    border-color: rgba(59, 130, 246, 0.5);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.03);
    border-color: rgba(59, 130, 246, 0.3);
  }
}

.loading-container {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-top: 0.75rem;
  color: #6b7280;
  font-size: 0.875rem;
}

.loading-spinner {
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid #e5e7eb;
  border-top-color: #3b82f6;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
}

.error-message {
  color: #dc2626;
  font-size: 0.875rem;
  margin-top: 0.75rem;
}

.quadrant-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.quadrant {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  height: 150px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.quadrant h3 {
  margin: 0 0 12px 0;
  font-size: 0.875rem;
  font-weight: 600;
  color: #374151;
  flex-shrink: 0;
}

.quadrant-content {
  flex-grow: 1;
  overflow-y: auto;
  padding-right: 8px;
}

.quadrant-content p {
  margin: 0;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #6b7280;
}

.quadrant-content ul {
  margin: 0;
  padding-left: 20px;
  list-style-type: disc;
}

.quadrant-content li {
  font-size: 0.875rem;
  line-height: 1.5;
  color: #6b7280;
}

.quadrant.loading p {
  height: 80px;
  border-radius: 4px;
  animation: content-loading 1.5s ease-in-out infinite;
  color: transparent;
  user-select: none;
}

.quadrant.loading ul {
  height: 80px;
  border-radius: 4px;
  animation: content-loading 1.5s ease-in-out infinite;
  color: transparent;
  user-select: none;
}

@keyframes content-loading {
  0% {
    background: rgba(0, 0, 0, 0.04);
  }
  50% {
    background: rgba(0, 0, 0, 0.08);
  }
  100% {
    background: rgba(0, 0, 0, 0.04);
  }
}

.loading-placeholder {
  height: 24px;
  border-radius: 4px;
  animation: content-loading 1.5s ease-in-out infinite;
  margin-bottom: 8px;
}

.mission-section {
  padding: 0.7875rem;
  background: #ffffff;
  border-radius: 14.4px;
  border: 1px solid #e5e7eb;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 0.5rem;
}

.mission-input {
  width: 100%;
  box-sizing: border-box;
  height: 42px;
  padding: 0.75rem 1rem;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  font-size: 0.875rem;
  line-height: 1.25rem;
  resize: none;
  transition: border-color 0.15s ease;
}

.mission-input:focus {
  border-color: #3b82f6;
  outline: none;
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.1);
}

.mission-input:disabled {
  background: #f9fafb;
  cursor: not-allowed;
  color: #6b7280;
}

.mission-input:disabled {
  background: linear-gradient(
    to right,
    #f3f4f6 8%,
    #f9fafb 18%,
    #f3f4f6 33%
  );
  background-size: 800px 104px;
  animation: shimmer 1.5s linear infinite;
}

.error-message {
  color: #e74c3c;
  font-size: 12px;
  margin-top: 4px;
}

.dialog-footer {
  padding: 16px 24px;
  border-top: 1px solid #e5e7eb;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
}

.dialog-footer button {
  min-width: 120px;
  max-width: fit-content;
}

.dialog-footer .start-mission-button {
  padding: 8px 24px;
  font-size: 0.875rem;
  font-weight: 500;
  color: white;
  background: linear-gradient(to right, #4F46E5, #6366F1);
  border: none;
  border-radius: 6px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  transition: all 0.2s ease;
  width: auto;
}

.steps-indicator {
  margin-right: auto;
  display: flex;
  gap: 8px;
}

.step-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #e5e7eb;
  transition: all 0.2s;
}

.step-dot.active {
  background: #3b82f6;
}

.step-dot.completed {
  background: #10b981;
}

.next-button {
  padding: 0.75rem 1.5rem;
  background: #3b82f6;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.15s ease;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}

.next-button:hover:not(:disabled) {
  background: #2563eb;
}

.next-button:disabled {
  background: #e5e7eb;
  cursor: not-allowed;
  opacity: 0.7;
}

.next-button:disabled.searching {
  background: #3b82f6;
  opacity: 0.9;
  animation: pulse 2s ease-in-out infinite;
}

.next-button:disabled.searching::after {
  content: "...";
  margin-left: 2px;
  animation: ellipsis 1.4s infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

@keyframes ellipsis {
  0% { content: "."; }
  33% { content: ".."; }
  66% { content: "..."; }
  100% { content: "."; }
}

@keyframes shimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.dialog-actions {
  display: flex;
  gap: 16px;
}

.back-button {
  padding: 12px 24px;
  background: #f3f4f6;
  border: none;
  border-radius: 8px;
  color: #4b5563;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.back-button:hover {
  background: #e5e7eb;
}

.next-button,
.start-mission-button {
  padding: 12px 32px;
  background: #3b82f6;
  border: none;
  border-radius: 8px;
  color: white;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.next-button:hover:not(:disabled),
.start-mission-button:hover:not(:disabled) {
  background: #2563eb;
  transform: translateY(-1px);
}

.next-button:disabled,
.start-mission-button:disabled {
  background: #95a5a6;
  cursor: not-allowed;
}

.start-mission-button {
  background: #10b981;
}

.start-mission-button:hover:not(:disabled) {
  background: #059669;
}

.step2-footer {
  padding: 24px 32px;
  border-top: 1px solid #e5e7eb;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  margin-top: 20px;
}

.step2-next-button {
  background: #3b82f6;
  color: white;
  padding: 12px 24px;
  border-radius: 6px;
  font-weight: 500;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
}

.step2-next-button:hover {
  background: #2563eb;
}

.prospect-image-container {
  display: flex;
  justify-content: center;
  margin: 32px 0 4px;
}

.prospect-image {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #f0f0f0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.initials-avatar {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, #4f46e5 0%, #6366f1 100%);
  color: white;
  font-size: 3rem;
  font-weight: 600;
  border: 3px solid #f0f0f0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.prospect-details-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.prospect-analysis-section {
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid rgba(229, 231, 235, 0.6);
  width: 100%;
}

.prospect-analysis-section h3 {
  font-size: 16px;
  font-weight: 600;
  color: #111827;
  margin: 0 0 16px;
}

.prospect-analysis-section p {
  font-size: 14px;
  line-height: 1.6;
  color: #4b5563;
  margin: 0;
  width: 100%;
  text-align: justify;
  hyphens: auto;
}

.percentage-indicator {
  font-weight: 500;
  padding: 6px 12px;
  border-radius: 8px;
  font-size: 0.875rem;
  display: inline-flex;
  align-items: center;
  gap: 6px;
  transition: all 0.2s ease;
}

.percentage-high {
  background: #ecfdf5;
  color: #047857;
}

.percentage-medium {
  background: #fefce8;
  color: #92400e;
}

.percentage-low {
  background: #fef2f2;
  color: #991b1b;
}

.percentage-indicator i {
  font-size: 12px;
  opacity: 0.8;
}

.prospects-layout {
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 2rem;
  height: calc(100vh - 200px);
  overflow: hidden;
}

.prospect-content {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 16px;
}

.dialog-footer {
  padding: 1.5rem;
  border-top: 1px solid #e5e7eb;
  display: flex;
  justify-content: flex-end;
}

.next-step-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  background: #2563eb;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.next-step-button:hover {
  background: #1d4ed8;
}

.next-step-button:disabled {
  background: #e5e7eb;
  cursor: not-allowed;
}

.next-step-button i {
  font-size: 0.75rem;
}

/* Step 2: Prospects */
.prospects-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  height: calc(80vh - 200px);
  overflow: hidden;
}

/* Left Panel - Prospects List */
.prospects-list {
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid #e5e7eb;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.prospects-header {
  padding: 20px;
  border-bottom: 1px solid #e5e7eb;
}

.prospects-header h3 {
  margin: 0 0 12px 0;
  color: #1f2937;
  font-size: 16px;
  font-weight: 600;
}

.search-box {
  position: relative;
}

.search-box input {
  width: 100%;
  padding: 8px 12px 8px 36px;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  font-size: 14px;
}

.search-box svg {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #9ca3af;
}

.prospects-scroll {
  overflow-y: auto;
  flex: 1;
  padding: 12px;
}

.prospect-item {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e5e7eb;
  cursor: pointer;
  transition: all 0.15s ease;
  background: white;
  margin-bottom: 16px;
}

.prospect-info {
  flex: 1;
  min-width: 0;
  padding-right: 12px;
}

.prospect-info h3 {
  margin: 0 0 6px 0;
  font-size: 1rem;
  font-weight: 600;
  color: #111827;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.25;
}

.prospect-info p {
  margin: 0 0 4px;
  font-size: 0.875rem;
  color: #4b5563;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.4;
}

.percentage-indicator {
  flex-shrink: 0;
  margin-left: 8px;
  font-weight: 500;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 0.75rem;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  transition: all 0.2s ease;
  opacity: 0.85;
  background: #f3f4f6;
  color: #6b7280;
  border: 1px solid #e5e7eb;
}

.prospect-item:hover {
  background: #f8fafc;
}

.prospect-item.selected {
  background: #f1f5f9;
  border-radius: 8px;
  margin: 0 4px;
  width: calc(100% - 8px);
  margin-bottom: 32px;
}

.prospect-item.selected .percentage-indicator {
  opacity: 1;
}

/* Middle Panel - Prospect Details */
.prospect-details {
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid #e5e7eb;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.prospect-header {
  text-align: center;
  padding-bottom: 24px;
  border-bottom: 1px solid #e5e7eb;
}

.large-avatar {
  width: 108px;
  height: 108px;
  border-radius: 50%;
  margin-bottom: 14.4px;
  object-fit: cover;
  border: 4px solid white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.prospect-header h2 {
  margin: 0 0 4px 0;
  color: #1f2937;
  font-size: 24px;
  font-weight: 600;
}

.prospect-header .title {
  color: #6b7280;
  font-size: 16px;
  margin: 0;
}

.contact-details {
  display: grid;
  gap: 16px;
}

.detail-item {
  display: flex;
  align-items: center;
  gap: 12px;
}

.detail-item span {
  font-weight: 500;
  color: #4b5563;
  width: 80px;
}

.detail-item a {
  color: #3b82f6;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 8px;
}

.detail-item a:hover {
  text-decoration: underline;
}

/* Right Panel - Analysis */
.prospect-analysis {
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid #e5e7eb;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.why-section, .pitch-section {
  padding: 20px;
  background: #f9fafb;
  border-radius: 8px;
}

.why-section h3, .pitch-section h3 {
  margin: 0 0 16px 0;
  color: #1f2937;
  font-size: 18px;
  font-weight: 600;
}

.fit-score {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.score-circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #eef2ff;
  border: 4px solid #c7d2fe;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #4f46e5;
}

.score-circle span {
  font-size: 24px;
  font-weight: 600;
}

.score-circle small {
  font-size: 12px;
  opacity: 0.8;
}

/* Step 3: Campaign Setup */
.campaign-setup {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  margin-bottom: 32px;
}

.setup-section {
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid #e5e7eb;
  padding: 24px;
}

.setup-section h3 {
  margin: 0 0 16px 0;
  color: #1f2937;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;
}

.setup-section .section-icon {
  color: #6b7280;
}

.calendly-input {
  width: 100%;
  padding: 12px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  font-size: 14px;
}

.calendly-input:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
}

.mode-selector {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.mode-button {
  background: #ffffff;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: all 0.2s;
}

.mode-button:hover {
  background: #f9fafb;
}

.mode-button.active {
  background: #eef2ff;
  border-color: #c7d2fe;
}

.mode-button svg {
  font-size: 24px;
  color: #4f46e5;
}

.mode-button span {
  font-weight: 500;
  color: #1f2937;
}

.mode-button small {
  font-size: 12px;
  color: #6b7280;
  text-align: center;
}

.mission-summary {
  margin-top: 2rem;
  padding: 1.25rem;
  background-color: #f8fafc;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
}

.mission-summary h3 {
  margin: 0 0 0.75rem 0;
  font-size: 0.875rem;
  font-weight: 600;
  color: #4b5563;
}

.mission-summary p {
  margin: 0;
  color: #1f2937;
  font-size: 0.875rem;
  line-height: 1.5;
}

.mission-refinement {
  width: 100%;
  height: 48px;
  padding: 0 16px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  font-size: 14px;
  color: #1f2937;
  background: #ffffff;
  transition: all 0.2s;
  margin-top: 8px;
}

.mission-refinement:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
}

.mission-text {
  color: #6b7280;
  font-size: 14px;
  margin-bottom: 4px;
}

.start-mission-button {
  width: 100%;
  padding: 16px;
  background: #4f46e5;
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: all 0.2s;
}

.start-mission-button:hover:not(:disabled) {
  background: #4338ca;
  transform: translateY(-1px);
}

.start-mission-button:disabled {
  background: #9ca3af;
  cursor: not-allowed;
}

.error-tooltip {
  position: absolute;
  right: 16px;
  top: 100%;
  margin-top: 8px;
  background: #fee2e2;
  color: #991b1b;
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;
  z-index: 10;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.2s ease-out;
}

.error-tooltip::before {
  content: '';
  position: absolute;
  top: -4px;
  right: 16px;
  width: 8px;
  height: 8px;
  background: #fee2e2;
  transform: rotate(45deg);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-4px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.full-width {
  width: 100% !important;
}

/* Step 2: Prospects */
.step-2 .prospects-grid {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.prospect-card {
  display: grid;
  grid-template-columns: 20% 30% 50%;
  background: #ffffff;
  border: 1px solid #e5e7eb;
  border-radius: 16px;
  overflow: hidden;
  transition: all 0.2s ease;
}

.prospect-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.prospect-left,
.prospect-middle,
.prospect-right {
  padding: 24px;
}

.prospect-left {
  background: #f9fafb;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.prospect-avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 16px;
}

.prospect-avatar-large {
  width: 108px;
  height: 108px;
  border-radius: 50%;
  margin-bottom: 14.4px;
}

.prospect-middle {
  border-right: 1px solid #e5e7eb;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  text-align: justify;
  hyphens: auto;
}

.prospect-middle p {
  margin: 0;
  line-height: 1.6;
  color: #4B5563;
  font-size: 14px;
}

.prospect-details {
  width: 100%;
  margin-top: 16px;
}

.prospect-details p {
  margin: 8px 0;
  color: #4b5563;
}

.prospect-right {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.fit-score {
  display: flex;
  align-items: center;
  gap: 8px;
}

.score {
  font-size: 24px;
  font-weight: 600;
  color: #3b82f6;
}

.label {
  color: #6b7280;
}

.select-prospect {
  margin-top: auto;
  padding: 12px 24px;
  background: #3b82f6;
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.select-prospect:hover:not(:disabled) {
  background: #2563eb;
}

.select-prospect:disabled {
  background: #9ca3af;
  cursor: not-allowed;
}

/* Step 3: Campaign Setup */
.step-3 .campaign-setup {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.scheduler-section input {
  width: 100%;
  padding: 16px;
  font-size: 16px;
  border: 2px solid #e5e7eb;
  border-radius: 12px;
  transition: all 0.2s ease;
}

.scheduler-section input:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
}

.mode-options {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 18px;
  margin-top: 16px;
}

.mode-option {
  padding: 24px;
  background: #f9fafb;
  border: 2px solid #e5e7eb;
  border-radius: 12px;
  text-align: left;
  cursor: pointer;
  transition: all 0.2s ease;
}

.mode-option.selected {
  border-color: #3b82f6;
  background: #eff6ff;
}

.mode-option:hover:not(.selected) {
  border-color: #e4e8ef;
}

.mode-option h4 {
  margin: 0 0 8px 0;
  color: #111827;
}

.mode-option p {
  margin: 0;
  color: #6b7280;
  font-size: 14px;
}

.selected-prospects {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  margin-top: 16px;
}

.prospect-mini-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

/* Footer */
.dialog-footer {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  border-top: 1px solid #e5e7eb;
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  z-index: 10;
  box-shadow: 0 -4px 6px -1px rgba(0, 0, 0, 0.1);
}

.steps-indicator {
  display: flex;
  gap: 8px;
}

.step-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #e5e7eb;
  transition: all 0.2s;
}

.step-dot.active {
  background: #3b82f6;
}

.step-dot.completed {
  background: #10b981;
}

.dialog-actions {
  display: flex;
  gap: 16px;
}

.back-button {
  padding: 12px 24px;
  background: #f3f4f6;
  border: none;
  border-radius: 8px;
  color: #4b5563;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.back-button:hover {
  background: #e5e7eb;
}

.next-button,
.start-mission-button {
  padding: 12px 32px;
  background: #3b82f6;
  border: none;
  border-radius: 8px;
  color: white;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.next-button:hover:not(:disabled),
.start-mission-button:hover:not(:disabled) {
  background: #2563eb;
  transform: translateY(-1px);
}

.next-button:disabled,
.start-mission-button:disabled {
  background: #95a5a6;
  cursor: not-allowed;
}

.start-mission-button {
  background: #10b981;
}

.start-mission-button:hover:not(:disabled) {
  background: #059669;
}

/* Loading States */
.loading-placeholder {
  height: 20px;
  background: linear-gradient(90deg, #f3f4f6 25%, #e5e7eb 50%, #f3f4f6 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
  border-radius: 4px;
}

@keyframes loading {
  0% { background-position: 200% 0; }
  100% { background-position: -200% 0; }
}

/* Animations */
.dialog-content > div {
  animation: slideIn 0.3s ease-out;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.analysis-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 20px;
}

.analysis-item {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.analysis-item h3 {
  margin-top: 0;
  color: #333;
  font-size: 1.2rem;
  margin-bottom: 12px;
}

.analysis-item ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.analysis-item li {
  padding: 4px 0;
  color: #666;
}

/* Prospects Section */
.prospects-section {
  padding: 20px;
  height: 100%;
  overflow-y: auto;
}

.prospects-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  gap: 18px;
  margin-top: 18px;
}

.prospect-card {
  background: #fff;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
}

.prospect-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.prospect-card h4 {
  margin: 0 0 8px;
  color: #2c3e50;
  font-size: 1.2em;
}

.prospect-card p {
  margin: 0 0 16px;
  color: #666;
  font-size: 0.9em;
  line-height: 1.5;
}

.prospect-details {
  border-top: 1px solid #eee;
  padding-top: 12px;
  margin-top: 12px;
}

.prospect-contacts {
  margin-bottom: 12px;
}

.prospect-contacts h5 {
  margin: 0 0 8px;
  color: #34495e;
  font-size: 0.9em;
}

.contact-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 8px;
  font-size: 0.85em;
}

.contact-info span {
  color: #666;
}

.prospect-card a {
  display: inline-block;
  color: #3498db;
  text-decoration: none;
  font-size: 0.9em;
  margin-top: 8px;
}

.prospect-card a:hover {
  text-decoration: underline;
}

.loading-message {
  text-align: center;
  color: #666;
  padding: 40px;
  font-size: 1.1em;
}

.no-prospects {
  text-align: center;
  color: #666;
  padding: 40px;
  font-size: 1.1em;
  background: #f8f9fa;
  border-radius: 8px;
  margin: 20px 0;
}

.step2-actions {
  display: flex;
  gap: 12px;
  justify-content: space-between;
  margin-top: 20px;
}

.back-button {
  padding: 8px 16px;
  border: 1px solid #ccc;
  background: #fff;
  color: #666;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.back-button:hover {
  background: #f5f5f5;
}

.start-button {
  padding: 8px 24px;
  background: #2ecc71;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.start-button:hover:not(:disabled) {
  background: #27ae60;
}

.start-button:disabled {
  background: #95a5a6;
  cursor: not-allowed;
}

.prospect-image-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.empty-state {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 2rem;
  background: #ffffff;
}

.empty-state-content {
  text-align: center;
  color: #6B7280;
}

.empty-state-content i {
  font-size: 3rem;
  margin-bottom: 1rem;
  color: #9CA3AF;
}

.empty-state-content h3 {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #374151;
}

.empty-state-content p {
  margin-bottom: 1.5rem;
  color: #6B7280;
}

.empty-state-content .secondary-button {
  padding: 0.75rem 1.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #374151;
  background-color: #F3F4F6;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: all 0.2s;
}

.empty-state-content .secondary-button:hover {
  background-color: #E5E7EB;
}

/* Prospect List Styles */
.prospects-container {
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 1rem;
  height: 100%;
  background: #ffffff;
}

.prospect-list {
  position: relative;
  border-right: 1px solid #E5E7EB;
  overflow-y: auto;
  height: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;
  display: flex;
  flex-direction: column;
}

.prospect-list::-webkit-scrollbar {
  display: none;
}

.prospect-item {
  padding: 20px;
  border-radius: 12px;
  margin-bottom: 16px;
  cursor: pointer;
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  background: white;
  border: 1px solid rgba(229, 231, 235, 0.5);
  position: relative;
}

.prospect-item:hover {
  background: linear-gradient(to right, #ffffff, #fafbff);
  border-color: rgba(224, 231, 255, 0.6);
  transform: translateY(-1px);
  box-shadow: 
    0 4px 6px -1px rgba(0, 0, 0, 0.05),
    0 2px 4px -1px rgba(0, 0, 0, 0.03);
}

.prospect-item.selected {
  background: linear-gradient(to right, #fafbff, #f8faff);
  border-color: rgba(79, 70, 229, 0.1);
  transform: translateY(-1px);
  box-shadow: 
    0 4px 6px -1px rgba(79, 70, 229, 0.05),
    0 2px 4px -1px rgba(79, 70, 229, 0.03);
  margin-bottom: 32px;
}

.prospect-item.selected + .prospect-item {
  margin-top: 8px;
}

.prospect-item:last-child {
  margin-bottom: 0;
}

.prospect-info {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.prospect-info h3 {
  margin: 0 0 4px;
  font-size: 15px;
  font-weight: 600;
  color: #1f2937;
  line-height: 1.4;
}

.prospect-info .title {
  font-size: 13.5px;
  color: #4b5563;
  margin: 0;
  line-height: 1.5;
  font-weight: 500;
}

.prospect-info .company {
  font-size: 13px;
  color: #6b7280;
  margin-top: 2px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.prospect-info .company i {
  font-size: 11px;
  opacity: 0.7;
}

.prospect-info .location {
  font-size: 12px;
  color: #9ca3af;
  margin-top: 2px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.prospect-info .location i {
  font-size: 9px;
  opacity: 0.7;
}

.percentage-indicator {
  position: absolute;
  top: 20px;
  right: 20px;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  font-size: 11px;
  padding: 3px 8px;
  border-radius: 12px;
  color: #6b7280;
  font-weight: 500;
  letter-spacing: 0.01em;
  opacity: 0.8;
}

.percentage-high {
  color: #047857;
  background: rgba(240, 253, 244, 0.8);
}

.percentage-medium {
  color: #92400e;
  background: rgba(254, 252, 232, 0.8);
}

.percentage-low {
  color: #991b1b;
  background: rgba(254, 242, 242, 0.8);
}

.percentage-indicator i {
  font-size: 9px;
}

.prospect-content {
  padding: 24px 24px 24px 0;
  overflow-y: auto;
  height: 100%;
  background: white;
  width: 100%;
  box-sizing: border-box;
}

.prospect-details {
  max-width: 100%;
  padding: 0 24px;
  box-sizing: border-box;
  margin: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.prospect-linkedin {
  margin-bottom: 24px;
}

.prospect-summary {
  margin: 0 0 32px;
  padding-bottom: 16px;
}

.prospect-analysis-section {
  margin-bottom: 32px;
  padding-bottom: 32px;
}

.section-title {
  font-size: 20px;
  font-weight: 600;
  color: #111827;
  margin: 2rem 0 1rem 1rem;
}

.key-indicators {
  margin: 0 0 16px;
  padding: 24px;
  background: white;
  border-radius: 16px;
  border: 1px solid #e5e7eb;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  width: 100%;
  box-sizing: border-box;
}

.prospect-analysis-section {
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid rgba(229, 231, 235, 0.6);
  width: 100%;
}

.prospect-reasons {
  padding: 24px;
  background: white;
  border-radius: 16px;
  border: 1px solid #e5e7eb;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  width: 100%;
  box-sizing: border-box;
}

.prospect-reasons h3 {
  font-size: 20px;
  font-weight: 600;
  color: #111827;
  margin: 0 0 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.prospect-reasons h3 i {
  color: #3b82f6;
  font-size: 1rem;
}

.prospect-reasons ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.prospect-reasons li {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  color: #4b5563;
  line-height: 1.6;
  font-size: 0.975rem;
  padding: 12px;
  background: white;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
  transition: all 0.2s ease;
}

.prospect-reasons li:hover {
  border-color: #d1d5db;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.prospect-reasons li i {
  color: #3b82f6;
  font-size: 0.875rem;
  margin-top: 4px;
}

.prospect-reasons li:last-child {
  margin-bottom: 0;
}

.analysis-section {
  margin-top: 32px;
}

.prospect-analysis {
  margin-top: 24px;
  padding: 20px;
  background: white;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
}

.prospect-analysis h3 {
  font-size: 14px;
  font-weight: 600;
  color: #374151;
  margin: 0 0 16px;
  padding-bottom: 8px;
  border-bottom: 1px solid #e5e7eb;
}

.analysis-item {
  margin-top: 12px;
}

.analysis-item h4 {
  font-size: 13px;
  font-weight: 500;
  color: #4b5563;
  margin: 0 0 8px;
}

.analysis-item p {
  margin: 0;
  font-size: 14px;
  line-height: 1.6;
  color: #6b7280;
  text-align: justify;
  hyphens: auto;
}

.analysis-item ul {
  list-style: none;
  padding: 0;
  margin: 8px 0 0;
}

.analysis-item li {
  font-size: 13px;
  color: #6b7280;
  padding: 4px 0;
  text-align: justify;
  line-height: 1.5;
}

.prospect-info .company {
  font-size: 13px;
  color: #6b7280;
  margin-top: 2px;
  display: flex;
}

.prospect-progress {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: rgba(99, 102, 241, 0.03);
  overflow: visible;
  z-index: 1000;
}

.progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: linear-gradient(90deg, #6366F1 0%, #4F46E5 100%);
  transition: width 0.3s ease-out;
  border-radius: 2px;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.prospect-progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #f3f4f6;
  overflow: hidden;
}

.prospect-info {
  padding: 16px;
  border-radius: 8px;
  background: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
}

.prospect-info h3 {
  margin: 0 0 4px;
  font-size: 16px;
  font-weight: 600;
  color: #1f2937;
  line-height: 1.4;
}

.prospect-info .title {
  font-size: 14px;
  color: #4b5563;
  margin-bottom: 4px;
  line-height: 1.4;
  text-align: justify;
  hyphens: auto;
}

.prospect-info .company {
  font-size: 13px;
  color: #6b7280;
  margin-top: 2px;
  display: flex;
  align-items: center;
  gap: 6px;
}

.prospect-info .company i {
  font-size: 12px;
  color: #9ca3af;
}

.prospect-summary {
  padding: 20px;
  background: #f9fafb;
  border-radius: 8px;
  margin-top: 16px;
}

.prospect-summary h4 {
  font-size: 14px;
  font-weight: 600;
  color: #374151;
  margin: 0 0 12px;
  padding-bottom: 8px;
  border-bottom: 1px solid #e5e7eb;
}

.prospect-summary p {
  margin: 0;
  font-size: 14px;
  line-height: 1.6;
  color: #4b5563;
  text-align: justify;
  hyphens: auto;
  white-space: pre-line;
}

.prospect-metrics {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin-top: 16px;
  padding: 16px;
  background: white;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
}

.metric-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 12px;
}

.metric-value {
  font-size: 24px;
  font-weight: 600;
  color: #2563eb;
  margin-bottom: 4px;
}

.metric-label {
  font-size: 12px;
  color: #6b7280;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.prospect-analysis {
  margin-top: 24px;
  padding: 20px;
  background: white;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
}

.prospect-analysis h3 {
  font-size: 14px;
  font-weight: 600;
  color: #374151;
  margin: 0 0 16px;
  padding-bottom: 8px;
  border-bottom: 1px solid #e5e7eb;
}

.analysis-item {
  margin-top: 12px;
}

.analysis-item h4 {
  font-size: 13px;
  font-weight: 500;
  color: #4b5563;
  margin: 0 0 8px;
}

.analysis-item p {
  margin: 0;
  font-size: 14px;
  line-height: 1.6;
  color: #6b7280;
  text-align: justify;
  hyphens: auto;
}

.analysis-item ul {
  list-style: none;
  padding: 0;
  margin: 8px 0 0;
}

.analysis-item li {
  font-size: 13px;
  color: #6b7280;
  padding: 4px 0;
  text-align: justify;
  line-height: 1.5;
}

.prospect-info .company {
  font-size: 13px;
  color: #6b7280;
  margin-top: 2px;
  display: flex;
}

.prospect-progress {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: rgba(99, 102, 241, 0.03);
  overflow: visible;
  z-index: 1000;
}

.progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: linear-gradient(90deg, #6366F1 0%, #4F46E5 100%);
  transition: width 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  box-shadow: 0 0 2px rgba(99, 102, 241, 0.3);
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.prospect-progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #f3f4f6;
  overflow: hidden;
}

.prospect-info {
  padding: 16px;
  border-radius: 8px;
  background: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
}

.prospect-info h3 {
  margin: 0 0 4px;
  font-size: 16px;
  font-weight: 600;
  color: #1f2937;
  line-height: 1.4;
}

.prospect-info .title {
  font-size: 14px;
  color: #4b5563;
  margin-bottom: 4px;
  line-height: 1.4;
  text-align: justify;
  hyphens: auto;
}

.prospect-info .company {
  font-size: 13px;
  color: #6b7280;
  margin-top: 2px;
  display: flex;
  align-items: center;
  gap: 6px;
}

.prospect-info .company i {
  font-size: 12px;
  color: #9ca3af;
}

.prospect-summary {
  padding: 20px;
  background: #f9fafb;
  border-radius: 8px;
  margin-top: 16px;
}

.prospect-summary h4 {
  font-size: 14px;
  font-weight: 600;
  color: #374151;
  margin: 0 0 12px;
  padding-bottom: 8px;
  border-bottom: 1px solid #e5e7eb;
}

.prospect-summary p {
  margin: 0;
  font-size: 14px;
  line-height: 1.6;
  color: #4b5563;
  text-align: justify;
  hyphens: auto;
  white-space: pre-line;
}

.prospect-metrics {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin-top: 16px;
  padding: 16px;
  background: white;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
}

.metric-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 12px;
}

.metric-value {
  font-size: 24px;
  font-weight: 600;
  color: #2563eb;
  margin-bottom: 4px;
}

.metric-label {
  font-size: 12px;
  color: #6b7280;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
 
.prospect-analysis {
  margin-top: 24px;
  padding: 20px;
  background: white;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
}

.prospect-analysis h3 {
  font-size: 14px;
  font-weight: 600;
  color: #374151;
  margin: 0 0 16px;
  padding-bottom: 8px;
  border-bottom: 1px solid #e5e7eb;
}

.analysis-item {
  margin-top: 12px;
}

.analysis-item h4 {
  font-size: 13px;
  font-weight: 500;
  color: #4b5563;
  margin: 0 0 8px;
}

.analysis-item p {
  margin: 0;
  font-size: 14px;
  line-height: 1.6;
  color: #6b7280;
  text-align: justify;
  hyphens: auto;
}

.analysis-item ul {
  list-style: none;
  padding: 0;
  margin: 8px 0 0;
}

.analysis-item li {
  font-size: 13px;
  color: #6b7280;
  padding: 4px 0;
  text-align: justify;
  line-height: 1.5;
}

.prospect-info .company {
  font-size: 13px;
  color: #6b7280;
  margin-top: 2px;
  display: flex;
}

.prospect-progress {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: rgba(99, 102, 241, 0.03);
  overflow: visible;
  z-index: 1000;
}

.progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: linear-gradient(90deg, #6366F1 0%, #4F46E5 100%);
  transition: width 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  box-shadow: 0 0 2px rgba(99, 102, 241, 0.3);
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.prospect-progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #f3f4f6;
  overflow: hidden;
}

.prospect-info {
  padding: 16px;
  border-radius: 8px;
  background: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
}

.prospect-info h3 {
  margin: 0 0 4px;
  font-size: 16px;
  font-weight: 600;
  color: #1f2937;
  line-height: 1.4;
}

.prospect-info .title {
  font-size: 14px;
  color: #4b5563;
  margin-bottom: 4px;
  line-height: 1.4;
  text-align: justify;
  hyphens: auto;
}

.prospect-info .company {
  font-size: 13px;
  color: #6b7280;
  margin-top: 2px;
  display: flex;
  align-items: center;
  gap: 6px;
}

.prospect-info .company i {
  font-size: 12px;
  color: #9ca3af;
}

.prospect-summary {
  padding: 20px;
  background: #f9fafb;
  border-radius: 8px;
  margin-top: 16px;
}

.prospect-summary h4 {
  font-size: 14px;
  font-weight: 600;
  color: #374151;
  margin: 0 0 12px;
  padding-bottom: 8px;
  border-bottom: 1px solid #e5e7eb;
}

.prospect-summary p {
  margin: 0;
  font-size: 14px;
  line-height: 1.6;
  color: #4b5563;
  text-align: justify;
  hyphens: auto;
  white-space: pre-line;
}

.prospect-metrics {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin-top: 16px;
  padding: 16px;
  background: white;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
}

.metric-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 12px;
}

.metric-value {
  font-size: 24px;
  font-weight: 600;
  color: #2563eb;
  margin-bottom: 4px;
}

.metric-label {
  font-size: 12px;
  color: #6b7280;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
 
.prospect-analysis {
  margin-top: 24px;
  padding: 20px;
  background: white;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
}

.prospect-analysis h3 {
  font-size: 14px;
  font-weight: 600;
  color: #374151;
  margin: 0 0 16px;
  padding-bottom: 8px;
  border-bottom: 1px solid #e5e7eb;
}

.analysis-item {
  margin-top: 12px;
}

.analysis-item h4 {
  font-size: 13px;
  font-weight: 500;
  color: #4b5563;
  margin: 0 0 8px;
}

.analysis-item p {
  margin: 0;
  font-size: 14px;
  line-height: 1.6;
  color: #6b7280;
  text-align: justify;
  hyphens: auto;
}

.analysis-item ul {
  list-style: none;
  padding: 0;
  margin: 8px 0 0;
}

.analysis-item li {
  font-size: 13px;
  color: #6b7280;
  padding: 4px 0;
  text-align: justify;
  line-height: 1.5;
}

.prospect-info .company {
  font-size: 13px;
  color: #6b7280;
  margin-top: 2px;
  display: flex;
}

.prospect-progress {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: rgba(99, 102, 241, 0.03);
  overflow: visible;
  z-index: 1000;
}

.progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: linear-gradient(90deg, #6366F1 0%, #4F46E5 100%);
  transition: width 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  box-shadow: 0 0 2px rgba(99, 102, 241, 0.3);
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.prospect-progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #f3f4f6;
  overflow: hidden;
}

.prospect-info {
  padding: 16px;
  border-radius: 8px;
  background: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
}

.prospect-info h3 {
  margin: 0 0 4px;
  font-size: 16px;
  font-weight: 600;
  color: #1f2937;
  line-height: 1.4;
}

.prospect-info .title {
  font-size: 14px;
  color: #4b5563;
  margin-bottom: 4px;
  line-height: 1.4;
  text-align: justify;
  hyphens: auto;
}

.prospect-info .company {
  font-size: 13px;
  color: #6b7280;
  margin-top: 2px;
  display: flex;
  align-items: center;
  gap: 6px;
}

.prospect-info .company i {
  font-size: 12px;
  color: #9ca3af;
}

.prospect-summary {
  padding: 20px;
  background: #f9fafb;
  border-radius: 8px;
  margin-top: 16px;
}

.prospect-summary h4 {
  font-size: 14px;
  font-weight: 600;
  color: #374151;
  margin: 0 0 12px;
  padding-bottom: 8px;
  border-bottom: 1px solid #e5e7eb;
}

.prospect-summary p {
  margin: 0;
  font-size: 14px;
  line-height: 1.6;
  color: #4b5563;
  text-align: justify;
  hyphens: auto;
  white-space: pre-line;
}

.prospect-metrics {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin-top: 16px;
  padding: 16px;
  background: white;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
}

.metric-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 12px;
}

.metric-value {
  font-size: 24px;
  font-weight: 600;
  color: #2563eb;
  margin-bottom: 4px;
}

.metric-label {
  font-size: 12px;
  color: #6b7280;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.prospect-analysis {
  margin-top: 24px;
  padding: 20px;
  background: white;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
}

.prospect-analysis h3 {
  font-size: 14px;
  font-weight: 600;
  color: #374151;
  margin: 0 0 16px;
  padding-bottom: 8px;
  border-bottom: 1px solid #e5e7eb;
}

.analysis-item {
  margin-top: 12px;
}

.analysis-item h4 {
  font-size: 13px;
  font-weight: 500;
  color: #4b5563;
  margin: 0 0 8px;
}

.analysis-item p {
  margin: 0;
  font-size: 14px;
  line-height: 1.6;
  color: #6b7280;
  text-align: justify;
  hyphens: auto;
}

.analysis-item ul {
  list-style: none;
  padding: 0;
  margin: 8px 0 0;
}

.analysis-item li {
  font-size: 13px;
  color: #6b7280;
  padding: 4px 0;
  text-align: justify;
  line-height: 1.5;
}

.prospect-info .company {
  font-size: 13px;
  color: #6b7280;
  margin-top: 2px;
  display: flex;
}

.prospect-progress {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: rgba(99, 102, 241, 0.03);
  overflow: visible;
  z-index: 1000;
}

.progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: linear-gradient(90deg, #6366F1 0%, #4F46E5 100%);
  transition: width 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  box-shadow: 0 0 2px rgba(99, 102, 241, 0.3);
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.prospect-progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #f3f4f6;
  overflow: hidden;
}

.prospect-info {
  padding: 16px;
  border-radius: 8px;
  background: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
}

.prospect-info h3 {
  margin: 0 0 4px;
  font-size: 16px;
  font-weight: 600;
  color: #1f2937;
  line-height: 1.4;
}

.prospect-info .title {
  font-size: 14px;
  color: #4b5563;
  margin-bottom: 4px;
  line-height: 1.4;
  text-align: justify;
  hyphens: auto;
}

.prospect-info .company {
  font-size: 13px;
  color: #6b7280;
  margin-top: 2px;
  display: flex;
  align-items: center;
  gap: 6px;
}

.prospect-info .company i {
  font-size: 12px;
  color: #9ca3af;
}

.prospect-summary {
  padding: 20px;
  background: #f9fafb;
  border-radius: 8px;
  margin-top: 16px;
}

.prospect-summary h4 {
  font-size: 14px;
  font-weight: 600;
  color: #374151;
  margin: 0 0 12px;
  padding-bottom: 8px;
  border-bottom: 1px solid #e5e7eb;
}

.prospect-summary p {
  margin: 0;
  font-size: 14px;
  line-height: 1.6;
  color: #4b5563;
  text-align: justify;
  hyphens: auto;
  white-space: pre-line;
}

.prospect-metrics {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin-top: 16px;
  padding: 16px;
  background: white;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
}

.metric-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 12px;
}

.metric-value {
  font-size: 24px;
  font-weight: 600;
  color: #2563eb;
  margin-bottom: 4px;
}

.metric-label {
  font-size: 12px;
  color: #6b7280;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.key-indicators {
  margin-top: 24px;
  padding: 24px;
  background: white;
  border-radius: 16px;
  border: 1px solid #e5e7eb;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  width: 100%;
  box-sizing: border-box;
}

.key-indicators h3 {
  font-size: 14px;
  font-weight: 600;
  color: #374151;
  margin: 0 0 16px;
  padding-bottom: 8px;
  border-bottom: 1px solid #e5e7eb;
}

.key-indicators ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.key-indicators li {
  font-size: 14px;
  color: #4b5563;
  padding: 8px 12px;
  background: #ffffff;
  border-radius: 6px;
  line-height: 1.5;
  display: flex;
  align-items: center;
  border: 1px solid #e5e7eb;
  transition: all 0.2s ease;
  gap: 8px;
}

.key-indicators li:hover {
  border-color: #d1d5db;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.key-indicators li i {
  color: #3b82f6;
  font-size: 0.875rem;
  margin-top: 4px;
}

.prospect-analysis {
  margin-top: 24px;
  padding: 20px;
  background: white;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
}

.prospect-analysis h3 {
  font-size: 14px;
  font-weight: 600;
  color: #374151;
  margin: 0 0 16px;
  padding-bottom: 8px;
  border-bottom: 1px solid #e5e7eb;
}

.analysis-item {
  margin-top: 12px;
}

.analysis-item h4 {
  font-size: 13px;
  font-weight: 500;
  color: #4b5563;
  margin: 0 0 8px;
}

.analysis-item p {
  margin: 0;
  font-size: 14px;
  line-height: 1.6;
  color: #6b7280;
  text-align: justify;
  hyphens: auto;
}

.analysis-item ul {
  list-style: none;
  padding: 0;
  margin: 8px 0 0;
}

.analysis-item li {
  font-size: 13px;
  color: #6b7280;
  padding: 4px 0;
  text-align: justify;
  line-height: 1.5;
}

.prospect-info .company {
  font-size: 13px;
  color: #6b7280;
  margin-top: 2px;
  display: flex;
}

.prospect-progress {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: rgba(99, 102, 241, 0.03);
  overflow: visible;
  z-index: 1000;
}

.progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: linear-gradient(90deg, #6366F1 0%, #4F46E5 100%);
  transition: width 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  box-shadow: 0 0 2px rgba(99, 102, 241, 0.3);
}

.progress-bar::after {
  content: '';
  position: absolute;
  right: -1px;
  top: 50%;
  transform: translateY(-50%);
  width: 3px;
  height: 3px;
  background: #6366F1;
  border-radius: 50%;
  box-shadow: 
    0 0 2px #6366F1,
    0 0 4px rgba(99, 102, 241, 0.5),
    0 0 6px rgba(79, 70, 229, 0.3);
}

.prospect-details {
  max-width: 100%;
  padding: 0 24px;
  box-sizing: border-box;
  margin: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.prospect-name {
  margin: 12px 0 4px;
  font-size: 1.5rem;
  font-weight: 600;
  color: #1f2937;
  line-height: 1.2;
}

.prospect-title {
  margin: 0 0 16px;
  font-size: 1rem;
  color: #4b5563;
  line-height: 1.4;
}

.prospect-linkedin {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: linear-gradient(to right, #0a66c2, #0077b5);
  color: white;
  text-decoration: none;
  border-radius: 6px;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.2s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
}

.prospect-linkedin:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background: linear-gradient(to right, #0077b5, #0a66c2);
}

.prospect-linkedin i {
  font-size: 1rem;
}

.prospect-summary {
  margin: 0 auto 24px;
  padding: 16px 20px;
  max-width: 600px;
  background: #f9fafb;
  border-radius: 8px;
  font-size: 0.9375rem;
  line-height: 1.6;
  color: #4b5563;
  text-align: justify;
  hyphens: auto;
  word-break: break-word;
  border: 1px solid #e5e7eb;
}

.dialog-footer .start-mission-button {
  padding: 8px 24px;
  font-size: 0.875rem;
  font-weight: 500;
  color: white;
  background: linear-gradient(to right, #4F46E5, #6366F1);
  border: none;
  border-radius: 6px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  transition: all 0.2s ease;
}

.dialog-footer .start-mission-button:hover {
  background: linear-gradient(to right, #4338CA, #4F46E5);
  transform: translateY(-1px);
}

.dialog-footer .start-mission-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  transform: none;
}

.dialog-footer .start-mission-button .icon {
  font-size: 1rem;
}

/* Score Breakdown Styles */
.score-breakdown {
  background: #ffffff;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.03);
  margin: 20px 0;
  border: 1px solid rgba(0, 0, 0, 0.04);
}

.score-breakdown h3 {
  color: #1a1a1a;
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 20px 0;
  display: flex;
  align-items: center;
  gap: 8px;
}

.score-breakdown h3 i {
  color: #4a90e2;
  font-size: 18px;
}

.score-metrics {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  max-width: 100%;
}

.score-metric-card {
  background: linear-gradient(145deg, #ffffff, #fafafa);
  border: 1px solid rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  padding: 12px;
  transition: all 0.2s ease;
  min-width: 0; 
}

.score-metric-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.score-metric-label {
  color: #666;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 0;
  align-items: flex-start;
  white-space: normal;
 }

.score-metric-label > span:first-child {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.score-metric-value {
  display: flex;
  align-items: center;
  gap: 8px;
}

.score-metric-header {
  display: flex;
  align-items: center;
  gap: 8px;
}

.score-metric-bar {
  flex: 1;
  height: 6px;
  background: #e5e7eb;
  border-radius: 3px;
  overflow: hidden;
  position: relative;
}

.score-metric-bar-fill {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-radius: 3px;
  transition: width 0.6s cubic-bezier(0.4, 0, 0.2, 1);
}

.score-metric-bar-fill.excellent {
  background: linear-gradient(to right, #34c759, #32d184);
}

.score-metric-bar-fill.good {
  background: linear-gradient(to right, #4a90e2, #5b9ee5);
}

.score-metric-bar-fill.average {
  background: linear-gradient(to right, #9b9b9b, #b5b5b5);
}

.score-metric-percentage {
  font-size: 15px;
  font-weight: 600;
  color: #1a1a1a;
  min-width: 45px;
  text-align: right;
}

.score-metric-icon {
  color: #4a90e2;
  font-size: 16px;
  margin-right: 8px;
}

/* Positive indicator classes */
.strength-indicator {
  font-size: 11px;
  font-weight: 500;
  padding: 2px 4px;
  border-radius: 4px;
  white-space: nowrap;
}

.strength-excellent {
  color: #34c759;
  background: rgba(52, 199, 89, 0.1);
}

.strength-good {
  color: #4a90e2;
  background: rgba(74, 144, 226, 0.1);
}

.strength-average {
  color: #9b9b9b;
  background: rgba(155, 155, 155, 0.1);
}

/* Remove old score styles */
.score-details,
.score-item,
.score-label,
.score.high,
.score.medium,
.score.low {
  display: none;
}
