/* Premium Enterprise Design */
.agent-chat-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #FFFFFF;
  border-radius: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

/* Refined Header */
.chat-header {
  padding: 24px 28px;
  background: #FFFFFF;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.header-title {
  margin-bottom: 20px;
}

.header-title h3 {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  color: #111827;
  letter-spacing: -0.3px;
}

.header-title .subtitle {
  margin-top: 4px;
  font-size: 13px;
  color: #6B7280;
  font-weight: 400;
}

/* Instagram-style Agent Avatars */
.agent-avatars {
  display: flex;
  padding: 0 28px 16px;
  overflow-x: auto;
  gap: 32px;
  background: #FFFFFF;
  scrollbar-width: none;
}

.agent-avatar-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  min-width: 80px; /* Ensure minimum width */
}

.agent-avatar-wrapper .agent-name {
  font-size: 13px;
  font-weight: 500;
  color: #2563EB;
  text-align: center;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2;
}

.agent-avatars::-webkit-scrollbar {
  display: none;
}

.agent-avatar {
  position: relative;
  width: 60px !important;
  height: 60px !important;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 16px !important;
  color: #111827;
  cursor: pointer;
  transition: all 0.2s ease;
  background: #F9FAFB;
  border: 1.5px solid #F3F4F6;
  overflow: visible !important;
  z-index: 1;
}

.agent-avatar::before {
  content: none !important;
}

.agent-avatar::after {
  content: '' !important;
  position: absolute !important;
  bottom: 0 !important;
  right: 0 !important;
  width: 12px !important;
  height: 12px !important;
  border-radius: 9999px !important;
  background-color: #10B981 !important;
  border: 2px solid #FFFFFF !important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) !important;
  z-index: 2 !important;
  transform: translate(2px, 2px) !important;
}

.agent-avatar.loading {
  background: #F3F4F6 !important;
}

.agent-avatar.error {
  background: #F3F4F6 !important;
  color: #F3F4F6 !important;
}

.agent-avatar:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

/* Smaller status indicator for chat avatars */
.message .agent-avatar::after {
  width: 10px;
  height: 10px;
  bottom: -1px;
  right: -1px;
  border-width: 2px;
  z-index: 2;
}

.agent-name {
  font-size: 11px;
  color: #6B7280;
  font-weight: 450;
  max-width: 70px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.agent-avatars .agent-name {
  margin-top: 8px;
  font-size: 12px;
  color: #6B7280;
  text-align: center;
  max-width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* iMessage-style Chat Container */
.messages-container {
  flex: 1;                /* Keep this for proper flex layout */
  display: flex;          /* Add this for column layout */
  flex-direction: column; /* Stack messages vertically */
  padding: 20px 0;        /* Keep original padding */
  background: #FFFFFF;    /* Keep white background */
  overflow-y: auto;       /* Enable scrolling */
  scroll-behavior: smooth;/* Smooth scrolling */
}

.message {
  display: flex;          /* Keep flex layout */
  margin-bottom: 16px;    /* Keep spacing */
  padding: 0 28px;        /* Keep padding */
  align-items: flex-start;/* Keep alignment */
  max-width: 100%;        /* Keep width constraint */
  background: transparent;/* Keep transparent background */
  box-shadow: none;       /* Keep no shadow */
  position: relative;     /* Add this */
  
  /* Add animation properties */
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;
}

.message.left {
  margin-right: auto;
  flex-direction: row;
}

.message.right {
  margin-left: auto;
  flex-direction: row-reverse;
}

.message-enter {
  opacity: 0;
  transform: translateY(20px);
}

.message-enter-active {
  opacity: 1;
  transform: translateY(0);
}

.message-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #F3F4F6;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  color: #4B5563;
  flex-shrink: 0;
  border: 2px solid #FFFFFF;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  text-transform: uppercase;
}

.message.right .message-avatar {
  margin-left: 12px;
}

.message.left .message-avatar {
  margin-right: 12px;
}

.system-avatar {
  background: #E5E7EB;
  font-size: 16px;
  border: 1px solid #D1D5DB;
}

.message.user .message-avatar {
  background: #2563EB;
  color: white;
  margin-right: 0;
  margin-left: 12px;
  border: 2px solid #FFFFFF;
}

.message-content {
  position: relative;
  margin: 0;
  max-width: 65%;
  background: #FFFFFF;
  padding: 12px 16px;
  border-radius: 12px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.message-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
}

.agent-name {
  font-weight: 600;
  color: #2196f3;
}

.timestamp {
  color: #666;
  font-size: 0.8rem;
}

.message-body {
  font-size: 14px;
  line-height: 1.5;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
}

.message-time {
  font-size: 11px;
  color: #6B7280;
  margin-top: 6px;
  font-weight: 500;
}

.message.agent .message-time {
  color: #8E8E93;
}

.message.right .message-time {
  color: rgba(255, 255, 255, 0.7);
  text-align: right;
}

.message.agent + .message.agent,
.message.user + .message.user {
  margin-top: 8px;
  position: relative;
}

.message.agent + .message.agent::before,
.message.user + .message.user::before {
  content: '';
  position: absolute;
  width: 2px;
  height: 8px;
  background: rgba(229, 231, 235, 0.8);
  z-index: 1;
}

.message.left + .message.left::before {
  left: 44px; /* Align with avatar */
  top: -8px;
}

.message.right + .message.right::before {
  right: 44px; /* Align with avatar */
  top: -8px;
}

.message.reply::after {
  content: '';
  position: absolute;
  width: 16px; /* Width of the curve */
  height: 20px; /* Height of the curve */
  border: 2px solid rgba(229, 231, 235, 0.6);
  border-bottom: none;
  border-right: none;
  top: -10px;
  z-index: 1;
}

.message.reply.left::after {
  left: -24px;
  border-top-left-radius: 8px;
}

.message.reply.right::after {
  right: -24px;
  border-top-right-radius: 8px;
  border-left: none;
  border-right: 2px solid rgba(229, 231, 235, 0.6);
}

.message.left {
  flex-direction: row;
  justify-content: flex-start;
  align-self: flex-start;
}

.message.right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-self: flex-end;
  width: 100%;
  padding-right: 12px;
}

.message.left .message-content {
  margin-left: 12px;
  margin-right: auto;
}

.message.right .message-content {
  margin-right: 8px;
  margin-left: 15%;
  background: #0B84FF;
  color: white;
  border-radius: 18px 18px 4px 18px;
  order: -1;
}

.message.right .message-content a {
  color: white;
  text-decoration: underline;
}

.message.right .message-avatar-container {
  margin-left: 8px;
  margin-right: 0;
  display: flex;
  align-items: flex-end;
}

.message.left .message-content::before {
  content: '';
  position: absolute;
  left: -6px;
  top: 10px;
  width: 12px;
  height: 12px;
  background: #FFFFFF;
  transform: rotate(45deg);
  border-left: 0.2px solid rgba(0, 0, 0, 0.05);
  border-bottom: 0.2px solid rgba(0, 0, 0, 0.05);
}

.message.right .message-content::before {
  content: '';
  position: absolute;
  right: -6px;
  top: 10px;
  width: 12px;
  height: 12px;
  background: #F0F7FF;
  transform: rotate(45deg);
  border-right: 0.2px solid rgba(0, 0, 0, 0.05);
  border-top: 0.2px solid rgba(0, 0, 0, 0.05);
}

.message.left .message-avatar {
  margin-right: 12px;
}

.message.right .message-avatar {
  margin-left: 12px;
}

.message.left .message-sender {
  text-align: left;
}

.message.right .message-sender {
  text-align: right;
}

.message.left .message-time {
  text-align: left;
}

.message.right .message-time {
  text-align: right;
}

/* System message specific styles */
.message.system .message-content {
  background: #F9FAFB;
  border: 1px solid #E5E7EB;
  color: #4B5563;
  font-size: 13px;
  padding: 10px 14px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.02);
}

.message.system .message-content::before {
  background: #F9FAFB;
  border-left: 1px solid #E5E7EB;
  border-bottom: 1px solid #E5E7EB;
}

/* Add styles for markdown content */
.message-content pre {
  background: #f1f1f1;
  padding: 1rem;
  border-radius: 4px;
  overflow-x: auto;
}

.message-content code {
  background: #f1f1f1;
  padding: 0.2rem 0.4rem;
  border-radius: 4px;
}

/* Style user messages */
.message.user .message-content {
  background: #F0F7FF;
  color: #1A1A1A;
  margin-left: auto;
  border: 1px solid #E5E7EB;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  border-top-right-radius: 4px;
}

/* Style agent messages */
.message.agent .message-content {
  background: white;
  color: #1A1A1A;
  border: 1px solid #E5E7EB;
  border-top-left-radius: 4px;
}

.message-sender {
  font-size: 13px;
  font-weight: 600;
  color: #4B5563;
  margin-bottom: 6px;
  letter-spacing: -0.2px;
}

.message.agent .message-sender {
  color: #666;
}

.message.user .message-sender {
  color: white;
}

/* Elegant Loading State */
.agent-chat-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 40px;
  background: #FFFFFF;
}

.loading-spinner {
  width: 36px;
  height: 36px;
  border: 2px solid #F3F4F6;
  border-top: 2px solid #6B7280;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
  margin-bottom: 20px;
}

.agent-chat-loading h3 {
  color: #111827;
  font-weight: 500;
  font-size: 16px;
  margin: 0;
  letter-spacing: -0.2px;
}

/* Subtle Animations */
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Minimal Scrollbar */
.messages-container::-webkit-scrollbar {
  width: 4px;
}

.messages-container::-webkit-scrollbar-track {
  background: transparent;
}

.messages-container::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 2px;
}

.messages-container::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.08);
}

/* Add styles for the feedback input section */
.feedback-input-container {
  padding: 16px 24px;
  background: #FFFFFF;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  display: flex;
  gap: 12px;
  align-items: center;
}

.feedback-input {
  flex: 1;
  padding: 12px 16px;
  border-radius: 24px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 14px;
  line-height: 1.5;
  resize: none;
  min-height: 24px;
  max-height: 120px;
  background: #F9FAFB;
  transition: all 0.2s ease;
}

.feedback-input:focus {
  outline: none;
  border-color: rgba(0, 0, 0, 0.2);
  background: #FFFFFF;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.send-button {
  padding: 8px 16px;
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #FFFFFF;
  color: #111827;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.send-button:hover {
  background: #F9FAFB;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.send-button:active {
  transform: translateY(0);
}

.send-button svg {
  width: 16px;
  height: 16px;
  color: #111827;
}

/* Text styling for markdown, html, etc */
.markdown-strong {
  font-weight: 600;
  color: #111827;
  letter-spacing: -0.2px;
}

.markdown-em {
  font-style: italic;
  color: #4B5563;
}

.markdown-link {
  color: #2563EB;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.2s ease;
  padding: 0.1em 0.2em;
  margin: 0 -0.2em;
  border-radius: 3px;
}

.markdown-link:hover {
  background: rgba(37, 99, 235, 0.1);
  text-decoration: none;
}

.message-text {
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  font-size: 14px;
  line-height: 1.5;
  color: #1A1A1A;
  letter-spacing: -0.1px;
}

.message-sender-name {
  font-weight: 600;
  margin-right: 0.5rem;
  color: #4B5563;
  letter-spacing: -0.2px;
}

.message-timestamp {
  color: #6B7280;
  font-size: 11px;
  font-weight: 500;
  margin-left: 0.5rem;
}

/* Message Links */
.message-text a,
.markdown-link {
  color: #2563EB;  /* Royal blue color for links */
  text-decoration: none;
  font-weight: 500;
  transition: all 0.2s ease;
  border-bottom: 1px solid transparent;
}

.message-text a:hover,
.markdown-link:hover {
  color: #1D4ED8;  /* Darker blue on hover */
  border-bottom-color: currentColor;
}

.message-text a:active,
.markdown-link:active {
  color: #1E40AF;  /* Even darker blue when clicked */
}

/* Ensure links stand out in dark mode */
@media (prefers-color-scheme: dark) {
  .message-text a,
  .markdown-link {
    color: #60A5FA;  /* Lighter blue for dark mode */
  }
  
  .message-text a:hover,
  .markdown-link:hover {
    color: #93C5FD;
  }
}

/* Agent Mentions */
.message-content .message-text .agent-mention {
  color: #4169E1 !important;
  font-weight: 600 !important;
  display: inline-block;
  padding: 0.1em 0.2em;
  margin: 0 -0.2em;
  border-radius: 3px;
  transition: all 0.2s ease;
  text-decoration: none;
}

.message-content .message-text .agent-mention:hover {
  background: rgba(65, 105, 225, 0.1);
  color: #1D4ED8 !important;
  text-decoration: underline;
}

.message-text .agent-mention:hover {
  color: #1D4ED8;  /* Darker blue on hover */
  text-decoration: underline;
}
.markdown-link:hover {
  color: #1D4ED8;
  text-decoration: underline;
}

/* Message avatar styling */
.message .agent-avatar-container {
  width: 32px;
  height: 32px;
  margin: 0;
  flex-shrink: 0;
}

.message.left .agent-avatar-container {
  margin-right: 12px;
}

.message.right .agent-avatar-container {
  margin-left: 12px;
}

/* Keep avatar size consistent and remove name under chat avatars */
.message .agent-avatar {
  width: 32px !important;
  height: 32px !important;
  font-size: 12px !important;
}

.message .agent-avatar-container .agent-name {
  display: none;
}

.message .agent-avatar.system {
  background: #E5E7EB !important;
  color: #4B5563 !important;
  font-size: 16px !important;
}

.message.reply {
  margin-left: 48px;  /* Indent replies */
  position: relative;
}

.message.reply::before {
  content: '';
  position: absolute;
  left: -24px;
  top: 16px;
  width: 16px;
  height: 2px;
  background: #E5E7EB;
}

.message.reply::after {
  content: '';
  position: absolute;
  left: -24px;
  top: 0;
  width: 2px;
  height: 16px;
  background: #E5E7EB;
}